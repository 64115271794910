import React from "react"
import styled from 'styled-components'
import Button from "../components/button"
// import { FaLinkedinIn, FaGithub, FaEnvelope, FaArrowLeft } from 'react-icons/fa';

const ContactForm = styled.div`
    & form {
      margin: 30px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    & input, textarea {
      background-color: #000000;
      color: #616161;
      padding: 25px 25px;
      width: 500px;
      border: 1px solid #2B2B2B;
      border-radius: 1px;
      margin-top: 20px;

    }
    & textarea {
      height: 100px;
    }
    & input::placeholder, textarea::placeholder {
      color: #2B2B2B;
    }
`;

export default () => (
  <ContactForm>
    <form>
      <label name="Name">
        <input type="text" name="name" placeholder="Name"/>
      </label>
      <label name="Email">
        <input type="text" name="email" placeholder="Email" />
      </label>
      <label name="Surname">
        <textarea type="text" name="message" placeholder="Your message ..." />
      </label>
      {/* <button type="submit">Submit</button> */}
      <Button>Submit</Button>
    </form>
  </ContactForm>
)
