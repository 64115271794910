import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import Button from "../components/button"
import ContactForm from "../components/contact-form"
import { FaArrowLeft } from 'react-icons/fa';

export default class ContactPage extends React.Component {
  state = {
    firstName: "",
    lastName: "",
  }
  render() {
    return (
      <div style={{ color: `teal` }}>
        <Navbar>
          <Link to="/"><FaArrowLeft /></Link>
        </Navbar>
        <Header headerText="Questions" />
        <p>If you'd like to enquire about a new project, or anything else, you can fill out the form below and I'll get back to you as soon as I can.</p>
        <ContactForm />
        <Link to="/">
          <Button secondary>Home</Button>
        </Link>
        <Footer />
      </div>
    )
  }
}